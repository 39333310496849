import React, { FC } from "react";
import TheSaintCategories from "./the-saint";
import TheOtherSaintCategories from "./the-other-saint";
import { useParams } from "react-router-dom";
import Header from "../layout/Header";
import { FormattedMessage, useIntl } from "react-intl";
import Footer from "../layout/Footer";
import { Content } from "../layout/Content";
import { Sponsor, SponsorImage, Sponsors } from "./common";
import getImagePath from "../../utils";
import Container from "../layout/Container";

const Categories: FC = () => {
  const { location } = useParams();
  const intl = useIntl();

  return (
    <Container>
      <Header hiddenTitle={intl.formatMessage({ id: "menu.the.saint.title" })}>
        <FormattedMessage id="menu" />
      </Header>
      <Content
        gap={1}
      >
        {location === "the-saint" && (
          <TheSaintCategories />
        )}
        {location === "sanki-ramen-bar" && (
          <TheOtherSaintCategories />
        )}
      </Content>
      <Sponsors>
        <Sponsor>
          <SponsorImage src={getImagePath("/assets/images/campari-logo.png")}/>
        </Sponsor>
      </Sponsors>
      <Footer />
    </Container>
  )
}

export default Categories;
