import React, { FC } from "react";
import styled from 'styled-components';
import getImagePath from "../../utils";
import Header from "../layout/Header";
import { FormattedMessage } from "react-intl";
import { Link as OriginalLink } from "react-router-dom";
import { motion } from "framer-motion";
import Footer from "../layout/Footer";
import { Content as OriginalContent } from "../layout/Content";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
  }

  &::before {
    top: 0;
    background-image: url(${() => getImagePath('/bg-the-saint.JPG')});
  }

  &::after {
    bottom: 0;
    background-image: url(${() => getImagePath('/bg-sanki-ramen-bar.JPG')});
  }

  @media (min-width: 768px) {

    &::before,
    &::after {
      width: 50%;
      height: 100%;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
    }
  }
`;

const Link = styled(OriginalLink)`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled(motion.div)<{ white?: boolean}>`
  background: ${({ theme, white }) => white ? 'white' : theme.colors.light} no-repeat center;
  border: 3px solid ${({ theme }) => theme.colors.dark};
  width: 50%;
  height: 50%;
  padding: 1.5rem;

  @media (min-width: 868px) {
    max-width: 20%;
  }
`
export const Icon = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

const Content = styled(OriginalContent)`
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

interface Props {
  legacyMode?: boolean;
}

const LandingPageV2: FC<Props> = ({
  legacyMode = false,
}) => {
  return (
    <Wrapper>
      <Header>
        <FormattedMessage id="menu" />
      </Header>
      <Content>
          <IconWrapper
            animate={{
              opacity: [0, 1],
              y: [40, 0],
            }}
          >
            <Link to="the-saint">
                <Icon src={getImagePath("/the-saint.png")} />
            </Link>
          </IconWrapper>
          <IconWrapper
            white
            animate={{
              y: [40, 0],
              opacity: [0, 1],
              transition: {
                delay: 0.1
              }
            }}
          >
            <Link to="sanki-ramen-bar">
                <Icon src={getImagePath("/sanki-large.JPG")} />
            </Link>
          </IconWrapper>
      </Content>
      <Footer hideLanguageSelector={legacyMode} />
    </Wrapper>
  )
}

export default LandingPageV2;
